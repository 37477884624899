<template>
	<div>
		<div class="p-center">
			<div class="footBox">
				<div class="footCon">
					<!-- <div class="foot_about">关于{{ footData.webName }}</div> -->
					<div class="hrefBox">
            <div
                :class="
								pdxsgl == 2 || pdxsgles == 2
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
                @click="gotoHref('/cooperation', 2)"
                @mouseenter="handleMouseEnter(2)"
                @mouseleave="handleMouseLeave"
            >
              免费服务
            </div>
						<div
							:class="
								pdxsgl == 3 || pdxsgles == 3
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/videoindex', 3)"
							@mouseenter="handleMouseEnter(3)"
							@mouseleave="handleMouseLeave"
						>
							商学院
						</div>
						<div
							:class="
								pdxsgl == 4 || pdxsgles == 4
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/fastFish', 4)"
							@mouseenter="handleMouseEnter(4)"
							@mouseleave="handleMouseLeave"
						>
							多多营销
						</div>
						<div
							:class="
								pdxsgl == 5 || pdxsgles == 5
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/community', 5)"
							@mouseenter="handleMouseEnter(5)"
							@mouseleave="handleMouseLeave"
						>
							社群服务
						</div>
						<!--          <div class="hrefTXt" @click="toHref('/tutorial')" :style="top==0?'color:#fff':''">-->
						<!--            教程视频-->
						<!--            <div class="line"></div>-->
						<!--          </div>-->
						<!--          <div class="hrefTXt" :style="top==0?'color:#fff':''" @click="toHref('/order')">-->
						<!--            服务订购-->
						<!--            <div class="line"></div>-->
						<!--          </div>-->
						<div
							:class="
								pdxsgl == 6 || pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="toLogin"
							@mouseenter="handleMouseEnter(6)"
							@mouseleave="handleMouseLeave"
						>
							用户登录
						</div>
						<!-- <div
							:class="
								pdxsgl == 6 || pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="toDownes"
							@mouseenter="handleMouseEnter(6)"
							@mouseleave="handleMouseLeave"
						>
							鱼抖抖下载
							<div class="line"></div>
						</div> -->
						<div
							:class="
								pdxsgl == 7 || pdxsgles == 7
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/alldownload', 7)"
							@mouseenter="handleMouseEnter(7)"
							@mouseleave="handleMouseLeave"
						>
							下载中心
						</div>
						<!-- <el-dropdown style="margin-left: 75px">
						<div
							class="hrefTXt"
							style="margin-left: 0; font-size: 16px"
							:style="top == 0 ? '' : ''"
						>
							应用下载
							<div class="line"></div>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								><a target="_blank" @click="toDown">{{
									foot.webName
								}}</a></el-dropdown-item
							>
							<el-dropdown-item
								><a target="_blank" @click="toDownes">{{
									foot.webNameSS2
								}}</a>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
					</div>
					<div class="yhdlzc">
						<!--              <div @click="$router.push('/order')">服务订购</div>-->
						<!--              <div @click="$router.push('/tutorial')">教学视频</div>-->
						<div class="yhdl" @click="toLogin">用户登录</div>
						<div class="yhdl" @click="toLogines">用户注册</div>
					</div>
					<div class="foot_center_center_txt">
						<div class="ysxggg">
							<span class="ystjyy">
								咨询热线（时间：09:00-18:00 工作日）
							</span>
						</div>
					</div>
					<div class="foot_center">
						<!-- <div class="foot_center_center">
							<div class="foot_center_center_phone">
								{{ footData.tel }}
							</div>
						</div> -->
						<div class="foot_center_right">
							<div class="foot_center_right_title">售前咨询</div>
							<div class="foot_center_right_img">
								<img :src="footData.shouqian" alt="" />
							</div>
						</div>
						<div class="foot_center_right foot_center_rightkf">
							<div class="foot_center_right_title">售后客服</div>
							<div class="foot_center_right_img">
								<img :src="footData.shouhou" alt="" />
							</div>
						</div>
						<div
							class="foot_center_right"
							style="margin-left: 20px"
						>
							<div class="foot_center_right_title">
								微信公众号
							</div>
							<div class="foot_center_right_img">
								<img
									:src="$baseImageUrl + footData.wechat_gzh"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div style="margin-top: 50px">
						<a href="" class="foot_bottom">{{ footData.bah }}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="m-center">
			<div class="footBox">
				<div class="footCon">
					<!-- <div class="foot_about">关于{{ footData.webName }}</div> -->
					<div class="hrefBox">
            <div
                :class="
								pdxsgl == 2 || pdxsgles == 2
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
                @click="gotoHref('/cooperation', 2)"
                @mouseenter="handleMouseEnter(2)"
                @mouseleave="handleMouseLeave"
            >
              免费服务
            </div>
						<div
							:class="
								pdxsgl == 3 || pdxsgles == 3
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/videoindex', 3)"
							@mouseenter="handleMouseEnter(3)"
							@mouseleave="handleMouseLeave"
						>
							商学院
						</div>
						<div
							:class="
								pdxsgl == 4 || pdxsgles == 4
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/fastFish', 4)"
							@mouseenter="handleMouseEnter(4)"
							@mouseleave="handleMouseLeave"
						>
							多多营销
						</div>
						<div
							:class="
								pdxsgl == 5 || pdxsgles == 5
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/community', 5)"
							@mouseenter="handleMouseEnter(5)"
							@mouseleave="handleMouseLeave"
						>
							社群服务
						</div>
						<!--          <div class="hrefTXt" @click="toHref('/tutorial')" :style="top==0?'color:#fff':''">-->
						<!--            教程视频-->
						<!--            <div class="line"></div>-->
						<!--          </div>-->
						<!--          <div class="hrefTXt" :style="top==0?'color:#fff':''" @click="toHref('/order')">-->
						<!--            服务订购-->
						<!--            <div class="line"></div>-->
						<!--          </div>-->
						<div
							:class="
								pdxsgl == 6 || pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="toLogin"
							@mouseenter="handleMouseEnter(6)"
							@mouseleave="handleMouseLeave"
						>
							用户登录
						</div>
						<!-- <div
							:class="
								pdxsgl == 6 || pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="toDownes"
							@mouseenter="handleMouseEnter(6)"
							@mouseleave="handleMouseLeave"
						>
							鱼抖抖下载
							<div class="line"></div>
						</div> -->
						<div
							:class="
								pdxsgl == 7 || pdxsgles == 7
									? 'hrefTXt textcol'
									: 'hrefTXt'
							"
							@click="gotoHref('/alldownload', 7)"
							@mouseenter="handleMouseEnter(7)"
							@mouseleave="handleMouseLeave"
						>
							下载中心
						</div>
						<!-- <el-dropdown style="margin-left: 75px">
						<div
							class="hrefTXt"
							style="margin-left: 0; font-size: 16px"
							:style="top == 0 ? '' : ''"
						>
							应用下载
							<div class="line"></div>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								><a target="_blank" @click="toDown">{{
									foot.webName
								}}</a></el-dropdown-item
							>
							<el-dropdown-item
								><a target="_blank" @click="toDownes">{{
									foot.webNameSS2
								}}</a>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
					</div>
					<div class="yhdlzc">
						<!--              <div @click="$router.push('/order')">服务订购</div>-->
						<!--              <div @click="$router.push('/tutorial')">教学视频</div>-->
						<div class="yhdl" @click="toLogin">用户登录</div>
						<div class="yhdl" @click="toLogines">用户注册</div>
					</div>
					<div class="foot_center_center_txt">
						<div class="ysxggg">
							<span class="ystjyy">
								咨询热线（时间：09:00-18:00 工作日）
							</span>
						</div>
					</div>
					<div class="foot_center">
						<!-- <div class="foot_center_center">
							<div class="foot_center_center_phone">
								{{ footData.tel }}
							</div>
						</div> -->
						<div class="foot_center_right">
							<div class="foot_center_right_title">售前咨询</div>
							<div class="foot_center_right_img">
								<img :src="footData.shouqian" alt="" />
							</div>
						</div>
						<div class="foot_center_right foot_center_rightkf">
							<div class="foot_center_right_title">售后客服</div>
							<div class="foot_center_right_img">
								<img :src="footData.shouhou" alt="" />
							</div>
						</div>
						<div
							class="foot_center_right"
							style="margin-left: 20px"
						>
							<div class="foot_center_right_title">
								微信公众号
							</div>
							<div class="foot_center_right_img">
								<img
									:src="$baseImageUrl + footData.wechat_gzh"
									alt=""
								/>
							</div>
						</div>
					</div>
					<div style="margin-top: 50px">
						<a href="" class="foot_bottom">{{ footData.bah }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			footData: {},
			pdxsgl: 0,
			pdxsgles: 0,
		}
	},
	mounted() {
		if (window.location.hash == '#/index') {
			this.pdxsgl = 1
		}
		if (window.location.hash == '#/videoindex') {
			this.pdxsgl = 2
		}

		if (window.location.hash == '#/fastFish') {
			this.pdxsgl = 3
		}
		if (window.location.hash == '#/community') {
			this.pdxsgl = 4
		}
		if (window.location.hash == '#/alldownload') {
			this.pdxsgl = 7
		}
		this.getFoot()
	},
	methods: {
		toDownes() {
			this.$http.post('member/login/getAddress?ss=2').then((res) => {
				if (res.code == 200) {
					window.location.href = res.data.download_address
				}
			})
		},
		toLogin() {
			window.open('https://member.yuduoduogongju.com/#/orderService')
		},
		toLogines() {
			window.open('https://member.yuduoduogongju.com/#/register')
		},
		gotoHref(e, num) {
			this.pdxsgl = num
			// this.$router.push(e)
			window.open('https://www.yuduoduogongju.com/#' + e)
			console.log(window.location)
		},
		handleMouseEnter(e) {
			this.pdxsgles = e
		},
		handleMouseLeave() {
			this.pdxsgles = 0
		},
		toLogin() {
			window.open(this.footData.login_address)
		},
		async getFoot() {
			let res = await this.$http({
				url: `home/index/foot`,
				method: 'post',
				data: {},
			})
			const { code, msg, data } = res
			if (code === 200) {
				this.footData = data
				sessionStorage.setItem('foot', JSON.stringify(data))
			}
		},
	},
}
</script>

<style scoped lang="less">
a {
	text-decoration: none;
}
.p-center {
	.footBox {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		box-sizing: border-box;
		// height: 326px;
		//background: #00062B;
		background: linear-gradient(90deg, #ec2f46 0%, #c30d23 100%);
		.footCon {
			width: 70%;
			text-align: center;
			.foot_about {
				font-size: 26px;
				font-weight: bold;
				color: #f1f2ff;
			}
			.foot_center {
				display: flex;
				margin-top: 20px;
				justify-content: center;

				.foot_center_left {
					width: 15%;
					font-size: 18px;
					font-family: Microsoft YaHei-Light, Microsoft YaHei;
					font-weight: 300;
					color: #f1f2ff;
					line-height: 45px;
				}
				.foot_center_center {
					text-align: center;
					width: 60%;
					margin: 20px 5% 0;
					.foot_center_center_phone {
						font-size: 36px;
						//font-family: DIN-Bold, DIN;
						font-weight: bold;
						color: #f1f2ff;
					}
				}
				.foot_center_right {
					background: rgba(0, 0, 0, 0.1);
					padding-bottom: 20px;

					width: 15%;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					.foot_center_right_title {
						font-size: 16px;
						font-weight: 300;
						color: #f1f2ff;
					}
					.foot_center_right_img {
						width: 144px;
						height: 144px;
						background: #999999;
						margin-top: 10px;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			.foot_bottom {
				font-size: 14px;
				font-weight: 300;
				color: #f1f2ff;
			}
		}
	}
}
.m-center {
	.footBox {
		background: linear-gradient(90deg, #ec2f46 0%, #c30d23 100%);
		padding: 30px 20px 10px;
		text-align: left;
		.foot_about {
			font-size: 14px;
			font-weight: 400;
			color: #f1f2ff;
		}
		.foot_center {
			display: flex;
			// justify-content: space-between;
			justify-content: center;
			// background: rgba(0, 0, 0, 0.1);

			.foot_center_left {
				.foot_center_left_top {
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid rgba(229, 229, 229, 0.1);
					margin-top: 10px;
					padding-top: 3px;
					padding-bottom: 10px;
					font-size: 10px;
					font-weight: 300;
					color: #f1f2ff;
				}
				.foot_center_left_center {
					font-size: 24px;
					font-weight: bold;
					margin-top: 10px;
					color: #f1f2ff;
				}
				.foot_center_left_bottom {
					font-size: 10px;
					font-weight: 300;
					color: #f1f2ff;
					margin-top: 4px;
				}
			}
			.foot_center_right {
				padding-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				font-size: 10px;
				font-weight: 300;
				color: #f1f2ff;
				background: rgba(0, 0, 0, 0.1);

				.foot_center_right_img {
					width: 75px;
					height: 75px;
					background: #999999;
					margin-bottom: 10px;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.foot_bottom {
			text-align: center;
			font-size: 8px;
			font-weight: 300;
			color: #f1f2ff;
			margin-top: 20px;
		}
	}
}
.foot_center_rightkf {
	margin-left: 20px;
}
.yhdl {
	font-style: 14px !important;
}
.hrefBox {
	box-sizing: border-box;
	// width: 120%;
	// margin-left: -80px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
	.hrefTXt {
		margin-left: 64px;
		cursor: pointer;
		position: relative;
		display: flex;
		justify-content: center;

		.line {
			width: 28px;
			height: 4px;
			background: transparent;
			border-radius: 4px 4px 4px 4px;
			position: absolute;
			top: 30px;
		}

		.lineHover {
			background: #df374b;
		}
	}
}
.textcol {
	// color: black;
}
.yhdlzc {
	// display: flex;
	margin: 10px;
	width: 100%;
	text-align: center;
}
.yhdl {
	display: inline-block;
	margin-right: 20px;
	color: #ffffff;
	font-size: 20px;
	cursor: pointer;
	padding: 10px;
	// margin-top: 20px;
	background: rgba(0, 0, 0, 0.1);
}
.foot_center_center_txt {
	width: 100%;
	text-align: center;
	font-size: 12px;
	font-weight: 300;
	color: #f1f2ff;
	margin-top: 10px;
	// margin-left: -20px;
}
@media screen and (max-width: 800px) {
	.hrefBox {
		font-size: 12px;
		margin-left: -80px;
	}
	// img {
	// 	height: 100% !important;
	// }
}
@media screen and (max-width: 700px) {
	.hrefBox {
		display: none;
	}
	// img {
	// 	height: 100% !important;
	// }
}
.ysxggg {
	width: 100%;
	text-align: center;
}
.ystjyy {
	margin: auto;
	background: rgba(0, 0, 0, 0.1);
	width: 20%;
	// width: 200px;
}
</style>
