var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-center"},[_c('div',{staticClass:"footBox"},[_c('div',{staticClass:"footCon"},[_c('div',{staticClass:"hrefBox"},[_c('div',{class:_vm.pdxsgl == 2 || _vm.pdxsgles == 2
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/cooperation', 2)},"mouseenter":function($event){return _vm.handleMouseEnter(2)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 免费服务 ")]),_c('div',{class:_vm.pdxsgl == 3 || _vm.pdxsgles == 3
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/videoindex', 3)},"mouseenter":function($event){return _vm.handleMouseEnter(3)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 商学院 ")]),_c('div',{class:_vm.pdxsgl == 4 || _vm.pdxsgles == 4
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/fastFish', 4)},"mouseenter":function($event){return _vm.handleMouseEnter(4)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 多多营销 ")]),_c('div',{class:_vm.pdxsgl == 5 || _vm.pdxsgles == 5
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/community', 5)},"mouseenter":function($event){return _vm.handleMouseEnter(5)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 社群服务 ")]),_c('div',{class:_vm.pdxsgl == 6 || _vm.pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":_vm.toLogin,"mouseenter":function($event){return _vm.handleMouseEnter(6)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 用户登录 ")]),_c('div',{class:_vm.pdxsgl == 7 || _vm.pdxsgles == 7
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/alldownload', 7)},"mouseenter":function($event){return _vm.handleMouseEnter(7)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 下载中心 ")])]),_c('div',{staticClass:"yhdlzc"},[_c('div',{staticClass:"yhdl",on:{"click":_vm.toLogin}},[_vm._v("用户登录")]),_c('div',{staticClass:"yhdl",on:{"click":_vm.toLogines}},[_vm._v("用户注册")])]),_vm._m(0),_c('div',{staticClass:"foot_center"},[_c('div',{staticClass:"foot_center_right"},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v("售前咨询")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.footData.shouqian,"alt":""}})])]),_c('div',{staticClass:"foot_center_right foot_center_rightkf"},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v("售后客服")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.footData.shouhou,"alt":""}})])]),_c('div',{staticClass:"foot_center_right",staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v(" 微信公众号 ")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.$baseImageUrl + _vm.footData.wechat_gzh,"alt":""}})])])]),_c('div',{staticStyle:{"margin-top":"50px"}},[_c('a',{staticClass:"foot_bottom",attrs:{"href":""}},[_vm._v(_vm._s(_vm.footData.bah))])])])])]),_c('div',{staticClass:"m-center"},[_c('div',{staticClass:"footBox"},[_c('div',{staticClass:"footCon"},[_c('div',{staticClass:"hrefBox"},[_c('div',{class:_vm.pdxsgl == 2 || _vm.pdxsgles == 2
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/cooperation', 2)},"mouseenter":function($event){return _vm.handleMouseEnter(2)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 免费服务 ")]),_c('div',{class:_vm.pdxsgl == 3 || _vm.pdxsgles == 3
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/videoindex', 3)},"mouseenter":function($event){return _vm.handleMouseEnter(3)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 商学院 ")]),_c('div',{class:_vm.pdxsgl == 4 || _vm.pdxsgles == 4
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/fastFish', 4)},"mouseenter":function($event){return _vm.handleMouseEnter(4)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 多多营销 ")]),_c('div',{class:_vm.pdxsgl == 5 || _vm.pdxsgles == 5
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/community', 5)},"mouseenter":function($event){return _vm.handleMouseEnter(5)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 社群服务 ")]),_c('div',{class:_vm.pdxsgl == 6 || _vm.pdxsgles == 6
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":_vm.toLogin,"mouseenter":function($event){return _vm.handleMouseEnter(6)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 用户登录 ")]),_c('div',{class:_vm.pdxsgl == 7 || _vm.pdxsgles == 7
									? 'hrefTXt textcol'
									: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/alldownload', 7)},"mouseenter":function($event){return _vm.handleMouseEnter(7)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 下载中心 ")])]),_c('div',{staticClass:"yhdlzc"},[_c('div',{staticClass:"yhdl",on:{"click":_vm.toLogin}},[_vm._v("用户登录")]),_c('div',{staticClass:"yhdl",on:{"click":_vm.toLogines}},[_vm._v("用户注册")])]),_vm._m(1),_c('div',{staticClass:"foot_center"},[_c('div',{staticClass:"foot_center_right"},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v("售前咨询")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.footData.shouqian,"alt":""}})])]),_c('div',{staticClass:"foot_center_right foot_center_rightkf"},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v("售后客服")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.footData.shouhou,"alt":""}})])]),_c('div',{staticClass:"foot_center_right",staticStyle:{"margin-left":"20px"}},[_c('div',{staticClass:"foot_center_right_title"},[_vm._v(" 微信公众号 ")]),_c('div',{staticClass:"foot_center_right_img"},[_c('img',{attrs:{"src":_vm.$baseImageUrl + _vm.footData.wechat_gzh,"alt":""}})])])]),_c('div',{staticStyle:{"margin-top":"50px"}},[_c('a',{staticClass:"foot_bottom",attrs:{"href":""}},[_vm._v(_vm._s(_vm.footData.bah))])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"foot_center_center_txt"},[_c('div',{staticClass:"ysxggg"},[_c('span',{staticClass:"ystjyy"},[_vm._v(" 咨询热线（时间：09:00-18:00 工作日） ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"foot_center_center_txt"},[_c('div',{staticClass:"ysxggg"},[_c('span',{staticClass:"ystjyy"},[_vm._v(" 咨询热线（时间：09:00-18:00 工作日） ")])])])}]

export { render, staticRenderFns }