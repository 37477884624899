<template>
	<div>
		<div class="p-center">
			<div
				class="header"
				:class="top == 0 ? 'headerHover' : 'headerNormal'"
			>
				<div class="logoBox" @click="tzshouy">
					<img :src="$baseImageUrl + foot.logo" alt="" />
					<div
						style="
							margin-left: 10px;
							font-size: 36px;
							font-weight: bold;
						"
					>
						{{ foot.webName }}
					</div>
					<!--          <img src="../../assets/images/web_name.png" alt="" >-->
				</div>
				<div class="hrefBox">
					<div
						:class="
							pdxsgl == 1 || pdxsgles == 1
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="gotoHref('/', 1)"
						@mouseenter="handleMouseEnter(1)"
						@mouseleave="handleMouseLeave"
					>
						首页
					</div>
          <div
              :class="
							pdxsgl == 2 || pdxsgles == 2
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
              @click="gotoHref('/cooperation', 2)"
              @mouseenter="handleMouseEnter(2)"
              @mouseleave="handleMouseLeave"
          >
            免费服务
          </div>
					<div
						:class="
							pdxsgl == 3 || pdxsgles == 3
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="gotoHref('/videoindex', 3)"
						@mouseenter="handleMouseEnter(3)"
						@mouseleave="handleMouseLeave"
					>
						商学院
					</div>
					<div
						:class="
							pdxsgl == 4 || pdxsgles == 4
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="gotoHref('/fastFish', 4)"
						@mouseenter="handleMouseEnter(4)"
						@mouseleave="handleMouseLeave"
					>
						多多营销
					</div>
					<div
						:class="
							pdxsgl == 5 || pdxsgles == 5
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="gotoHref('/community', 5)"
						@mouseenter="handleMouseEnter(5)"
						@mouseleave="handleMouseLeave"
					>
						社群服务
					</div>
					<!--          <div class="hrefTXt" @click="toHref('/tutorial')" :style="top==0?'color:#fff':''">-->
					<!--            教程视频-->
					<!--            <div class="line"></div>-->
					<!--          </div>-->
					<!--          <div class="hrefTXt" :style="top==0?'color:#fff':''" @click="toHref('/order')">-->
					<!--            服务订购-->
					<!--            <div class="line"></div>-->
					<!--          </div>-->
					<div
						:class="
							pdxsgl == 6 || pdxsgles == 6
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="toLogin"
						@mouseenter="handleMouseEnter(6)"
						@mouseleave="handleMouseLeave"
					>
						用户登录
					</div>
					<!-- <div
						:class="
							pdxsgl == 6 || pdxsgles == 6
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="toDownes"
						@mouseenter="handleMouseEnter(6)"
						@mouseleave="handleMouseLeave"
					>
						鱼抖抖下载
						<div class="line"></div>
					</div> -->
					<div
						:class="
							pdxsgl == 7 || pdxsgles == 7
								? 'hrefTXt textcol yangshi'
								: 'hrefTXt'
						"
						@click="gotoHref('/alldownload', 7)"
						@mouseenter="handleMouseEnter(7)"
						@mouseleave="handleMouseLeave"
					>
						下载中心
					</div>
					<!-- <el-dropdown style="margin-left: 75px">
						<div
							class="hrefTXt"
							style="margin-left: 0; font-size: 16px"
							:style="top == 0 ? '' : ''"
						>
							应用下载
							<div class="line"></div>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								><a target="_blank" @click="toDown">{{
									foot.webName
								}}</a></el-dropdown-item
							>
							<el-dropdown-item
								><a target="_blank" @click="toDownes">{{
									foot.webNameSS2
								}}</a>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
				</div>
			</div>
			<div style="height: 68px"></div>
		</div>
		<div class="m-center">
			<div class="header">
				<div class="logoBox">
					<img :src="$baseImageUrl + foot.logo" alt="" />
					<div
						style="
							margin-left: 10px;
							font-size: 26px;
							font-weight: bold;
						"
					>
						{{ foot.webName }}
					</div>
					<!--          <img src="../assets/images/web_name.png" alt="">-->
				</div>
				<div class="selectBox">
					<img
						src="@/assets/images/select.png"
						alt=""
						v-if="!topShow"
						@click="openTop"
						class="seImg"
					/>
					<img
						src="@/assets/images/close_icon.png"
						alt=""
						v-else
						@click="closeTop"
						class="clImg"
					/>
				</div>
			</div>
			<div style="height: 64px"></div>
			<div class="topCon" v-if="topShow">
				<div
					:class="
						pdxsgl == 1 || pdxsgles == 1
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="gotoHref('/', 1)"
					@mouseenter="handleMouseEnter(1)"
					@mouseleave="handleMouseLeave"
				>
					首页
				</div>
        <div
            :class="
							pdxsgl == 2 || pdxsgles == 2
							? 'hrefTXt textcol'
							: 'hrefTXt'
						"
            @click="gotoHref('/cooperation', 2)"
            @mouseenter="handleMouseEnter(2)"
            @mouseleave="handleMouseLeave"
        >
          免费服务
        </div>
				<div
					:class="
						pdxsgl == 3 || pdxsgles == 3
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="gotoHref('/videoindex', 3)"
					@mouseenter="handleMouseEnter(3)"
					@mouseleave="handleMouseLeave"
				>
					商学院
				</div>

				<div
					:class="
						pdxsgl == 4 || pdxsgles == 4
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="gotoHref('/fastFish', 4)"
					@mouseenter="handleMouseEnter(4)"
					@mouseleave="handleMouseLeave"
				>
					多多营销
				</div>
				<div
					:class="
						pdxsgl == 5 || pdxsgles == 5
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="gotoHref('/community', 5)"
					@mouseenter="handleMouseEnter(5)"
					@mouseleave="handleMouseLeave"
				>
					社群服务
				</div>
				<!--          <div class="hrefTXt" @click="toHref('/tutorial')" :style="top==0?'color:#fff':''">-->
				<!--            教程视频-->
				<!--            <div class="line"></div>-->
				<!--          </div>-->
				<!--          <div class="hrefTXt" :style="top==0?'color:#fff':''" @click="toHref('/order')">-->
				<!--            服务订购-->
				<!--            <div class="line"></div>-->
				<!--          </div>-->
				<div
					:class="
						pdxsgl == 6 || pdxsgles == 6
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="toLogin"
					@mouseenter="handleMouseEnter(6)"
					@mouseleave="handleMouseLeave"
				>
					用户登录
				</div>
				<!-- <div
					:class="
						pdxsgl == 6 || pdxsgles == 6
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="toDownes"
					@mouseenter="handleMouseEnter(6)"
					@mouseleave="handleMouseLeave"
				>
					鱼抖抖下载
					<div class="line"></div>
				</div> -->
				<div
					:class="
						pdxsgl == 7 || pdxsgles == 7
							? 'hrefTXt textcol'
							: 'hrefTXt'
					"
					@click="gotoHref('/alldownload', 7)"
					@mouseenter="handleMouseEnter(7)"
					@mouseleave="handleMouseLeave"
				>
					下载中心
				</div>
				<!-- <el-dropdown style="width: 100%">
					<div class="topCell">
						<div>应用下载</div>
						<img src="@/assets/images/right_icon.png" alt="" />
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							><a @click="toDown" target="_blank">{{
								foot.webName
							}}</a></el-dropdown-item
						>
						<el-dropdown-item
							><a @click="toDownes" target="_blank">{{
								foot.webNameSS2
							}}</a></el-dropdown-item
						>
					</el-dropdown-menu>
				</el-dropdown> -->

				<div class="serve">
					<div v-for="(item, index) in csListData" :key="index">
						<div
							class="severCon severCon_phone"
							v-if="item.type == 3"
						>
							<div class="phoneTip">
								<div class="wcImg">
									{{ item.info }}
								</div>
								<i class="el-icon-caret-bottom arrow"></i>
							</div>
							<img
								class="severImg"
								src="@/assets/images/phone.png"
								alt=""
							/>
							<div>客服热线</div>
						</div>

						<a
							class="severCon"
							title="点击这里给我发消息"
							:href="
								'http://wpa.qq.com/msgrd?v=3&amp;uin=' +
								item.info +
								'&amp;site=qq&amp;menu=yes'
							"
							target="_blank"
							v-if="item.type == 2"
						>
							<img
								class="severImg"
								src="@/assets/images/qq.png"
								alt=""
							/>
							<div>在线客服</div>
						</a>
						<div
							class="severCon severCon_wc"
							@click="opwcShow"
							v-if="item.type == 1"
						>
							<div class="wcTip">
								<div class="wcImg">
									<img
										:src="$baseImageUrl + item.info"
										alt=""
									/>
								</div>
								<i class="el-icon-caret-bottom arrow"></i>
							</div>
							<img
								class="severImg"
								src="@/assets/images/weChat.png"
								alt=""
							/>
							<div>微信客服</div>
						</div>
						<div
							class="severCon severCon_wc"
							@click="opwcShow"
							v-if="item.type == 4"
						>
							<div class="wcTip">
								<div class="wcImg">
									<img
										:src="$baseImageUrl + item.info"
										alt=""
									/>
								</div>
								<i class="el-icon-caret-bottom arrow"></i>
							</div>
							<img
								class="severImg"
								src="@/assets/images/weChat_r2.png"
								style="border-radius: 50%"
								alt=""
							/>
							<div>公众号客服</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			index: 0,
			topShow: false,
			wcShow: false,
			foot: {},
			csListData: '',
			top: 0,
			pdxsgl: 0,
			pdxsgles: 0,
		}
	},
	mounted() {
		if (window.location.hash == '#/index') {
			this.pdxsgl = 1
		}
    if (window.location.hash == '#/cooperation') {
      this.pdxsgl = 2
    }
		if (window.location.hash == '#/videoindex') {
			this.pdxsgl = 3
		}

		if (window.location.hash == '#/fastFish') {
			this.pdxsgl = 4
		}
		if (window.location.hash == '#/community') {
			this.pdxsgl = 5
		}
		if (window.location.hash == '#/alldownload') {
			this.pdxsgl = 7
		}
		this.index = this.$route.meta.index
		this.getFoot()
		this.getCsList()
		if (this.$route.query.inCode) {
			localStorage.setItem('inCode', this.$route.query.inCode)
		}
	},
	methods: {
		tzshouy() {
			this.$router.push('/index')
		},
		gotoHref(e, num) {
			this.pdxsgl = num
			this.$router.push(e)
		},
		handleMouseEnter(e) {
			this.pdxsgles = e
		},
		handleMouseLeave() {
			this.pdxsgles = 0
		},
		toDown() {
			this.$http.post('member/login/getAddress').then((res) => {
				if (res.code == 200) {
					window.location.href = res.data.download_address
				}
			})
		},
		toDownes() {
			this.$http.post('member/login/getAddress?ss=2').then((res) => {
				if (res.code == 200) {
					window.location.href = res.data.download_address
				}
			})
		},
		toLogin() {
			window.open(this.foot.login_address)
		},
		async getFoot() {
			this.foot = JSON.parse(sessionStorage.getItem('foot'))
			if (!this.foot) {
				let res = await this.$http({
					url: `home/index/foot`,
					method: 'post',
					data: {},
				})
				const { code, msg, data } = res
				if (code === 200) {
					this.foot = data
					sessionStorage.setItem('foot', JSON.stringify(data))
				}
			}
		},
		async getCsList() {
			let res = await this.$http({
				url: `home/index/cs_list`,
				method: 'post',
				data: {},
			})
			const { code, msg, data } = res
			if (code === 200) {
				this.csListData = data
			}
		},
		toHref(row) {
			this.$router.push(row)
		},
		toHref_m(row) {
			this.$router.push(row)
			this.topShow = false
		},
		openTop() {
			this.topShow = true
		},
		closeTop() {
			this.topShow = false
		},
		opwcShow() {
			this.wcShow = true
		},
	},
}
</script>

<style scoped lang="less">
a {
	text-decoration: none;
	color: #333;
}

.p-center {
	.header {
		width: 100%;
		height: 68px;
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		z-index: 999;

		.logoBox {
			cursor: pointer;

			//width: 48px;
			height: 48px;
			border-radius: 4px 4px 4px 4px;
			margin-right: 20%;
			display: flex;
			align-items: center;

			img {
				width: auto;
				max-height: 100%;
				border-radius: 4px 4px 4px 4px;
			}
		}
	}

	.headerNormal {
		background: #ffffff;
		box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
	}

	.headerHover {
		//background: linear-gradient(90deg, #7487FF 0%, #4C64FE 100%);
		//background: linear-gradient(90deg, #EC2F46 0%, #C30D23 100%);
		background-color: #ffffff;
	}

	.hrefBox {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 400;
		color: #333333;

		.hrefTXt {
			padding-bottom: 5px;
			margin-left: 74px;
			cursor: pointer;
			position: relative;
			display: flex;
			justify-content: center;

			.line {
				width: 28px;
				height: 4px;
				background: transparent;
				border-radius: 4px 4px 4px 4px;
				position: absolute;
				top: 30px;
			}

			.lineHover {
				background: #df374b;
			}
		}
	}
}

.m-center {
	.header {
		width: 90%;
		padding: 0 5%;
		height: 64px;
		background: #ffffff;
		box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
		position: fixed;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		z-index: 999;

		.logoBox {
			//width: 39px;
			height: 39px;
			display: flex;
			border-radius: 4px 4px 4px 4px;

			img {
				width: auto;
				max-height: 100%;
				border-radius: 4px 4px 4px 4px;
			}
		}
	}

	.selectBox {
		.seImg {
			width: 25px;
			height: 21px;
		}

		.clImg {
			width: 15.5px;
			height: 15.5px;
		}
	}

	a {
		text-decoration: none;
	}

	.topCon {
		background: #f3f3f3;
		position: fixed;
		z-index: 999;
		top: 64px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 20px;

		.topCell {
			height: 50px;
			border-bottom: 1px solid #e5e5e5;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 12px;
			font-weight: 400;
			color: #333333;

			img {
				width: 10px;
				height: 14px;
			}
		}

		.phoneTip {
			display: none;
			position: absolute;
			justify-content: center;
			top: -40px;

			.wcImg {
				padding: 5px 10px;
				background: #ffffff;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
				border-radius: 4px 4px 4px 4px;
				align-items: center;
				justify-content: center;
				display: flex;
			}

			.arrow {
				position: absolute;
				font-size: 30px;
				color: #ffffff;
				bottom: -18px;
			}
		}

		.wcTip {
			display: none;
			position: absolute;
			justify-content: center;
			top: -144px;

			.wcImg {
				width: 130px;
				height: 130px;
				background: #ffffff;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
				border-radius: 4px 4px 4px 4px;
				align-items: center;
				justify-content: center;
				display: flex;

				img {
					width: 110px;
					height: 110px;
				}
			}

			.arrow {
				position: absolute;
				font-size: 30px;
				color: #ffffff;
				bottom: -18px;
			}
		}

		.serve {
			display: flex;
			align-items: center;
			justify-content: space-around;

			.severCon {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				font-size: 12px;
				font-weight: 300;
				color: #666666;
				margin-top: 100px;
				position: relative;

				.severImg {
					width: 54px;
					height: 54px;
					margin-bottom: 10px;
				}
			}

			.severCon_wc:hover .wcTip {
				display: flex;
			}

			.severCon_phone:hover .phoneTip {
				display: flex;
			}
		}
	}
}
.textcol {
	color: red;
}
.yangshi {
	border-bottom: 3px solid red;
}
</style>
